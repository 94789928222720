const particleParams = {
    particles: {
        number: {
            value: 200,
            density: {
            enable: true,
            value_area: 800
            }
        },
        color: {
            value: "#ffffff"
        },
        shape: {
            type: "circle",
            stroke: {
                width: 0,
                color: "#000000"
            },
            polygon: {
                nb_sides: 5
            },
            image: {
                src: "img/github.svg",
                width: 100,
                height: 100
            }
        },
        opacity: {
            value: 0.8,
            random: false,
            anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
            }
        },
        size: {
            value: 2,
            random: true,
            anim: {
                enable: false,
                speed: 40,
                size_min: 1,
                sync: false
            }
        },
        line_linked: {
            enable: false,
            distance: 110,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
        },
        move: {
            enable: true,
            speed: 1,
            direction: "top",
            random: false,
            straight: true,
            out_mode: "bounce",
            bounce: false,
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
        }
    },
        
    interactivity: {
        detect_on: "canvas",
        events: {
            onhover: {
                enable: true,
                mode: "grab"
            },
            onclick: {
                enable: true,
                mode: "push"
            },
            resize: true
        },
        
        modes: {
            grab: {
                distance: 150,
                line_linked: {
                opacity: 1
                }
            },

            bubble: {
                distance: 150,
                size: 20,
                duration: 1,
                opacity: 8,
                speed: 3
            },

            repulse: {
                distance: 120,
                duration: 0.4
            },

            push: {
                particles_nb: 4
            },

            remove: {
                particles_nb: 2
            }
        }
    },
    retina_detect: true
}

export default particleParams;
/* @flow */
import * as React from 'react';

import Button from "@material-ui/core/Button";

import logoMosaic from "../../../../../style/images/mosaic.png";
import btnDemo from "../../../../../style/images/demo.png";

import "./project-page.css";

type Props = {
	image: string,
	title: number,
	blurb: number,
	description: string,
	tags: Array,
	toggleProjectMosaic: Function,
	link: string,
};

const ProjectPage = (props: Props) => {
	console.log(props.link)
	return(
		<div className="project-page-ctn">
			<img className="background-img" src={props.image} alt=""/>
			<div className="blue-filter"></div>
			<img className="project-mosaic" src={logoMosaic} onClick={props.toggleProjectMosaic} alt="mosaic"/>
			<div className="project-ctn">

				<img className="project-img hideOnMobile" src={props.image} alt=""/>
				<div className="project-content">
					<h1 className="project-title">{props.title}</h1>
					<p className="project-description">{props.description}</p>
					<div className="project-tags">
							{props.tags.map((tag, index) => {
									return(<Button className="btn-tags" key={index}>{tag}</Button>)
							})}
					</div>

				</div>
        {
          props.link !== "" ? 
				  <a href={props.link} target="_blank" rel="noopener noreferrer"><img className="project-demo" src={btnDemo} alt="Demo" /></a> :
          <Button className="project-demo">Still in progress</Button>
        }
			</div>

				
		</div>
	)
}

export default ProjectPage;
import React from 'react';
import Root from "./root";
import './index.css';
import * as serviceWorker from './serviceWorker';

import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

render((
    <BrowserRouter>
        <Root />
    </BrowserRouter>
), document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import PistachioImg from "../../../../style/images/pistachio.jpg";
import CastoImg from "../../../../style/images/casto.jpeg";
import SpotifyImg from "../../../../style/images/vinyle.jpeg";
import Cards from "../../../../style/images/playing-cards.jpg";
import Ugi from "../../../../style/images/ugi.png";
import VueJs from "../../../../style/images/header-vue-js.jpg";
import RideAndSmile from "../../../../style/images/rideandsmile.PNG";
import NoName from "../../../../style/images/tpl.PNG";

export const projectsArray = [
  {
    id: 1,
    title: "No Name 65",
    image: NoName,
    description: "Personal project for testing some features like custom cursor, section scroll and animations",
    tags: ["VueJs", "Vuex", "SCSS"],
    blurb: "Template example",
    link: "https://noname65.netlify.app/",
  },
  {
    id: 2,
    title: "Vue components",
    image: VueJs,
    description: "Library of VueJs reusable components",
    tags: ["VueJs", "Vuex", "SCSS"],
    blurb: "VueJs reusable components",
    link: "https://github.com/Mamouel/vue-components",
  },
  {
    id: 3,
    title: "Ride and Smile",
    image: RideAndSmile,
    description: "Website for ski/snowboard school",
    tags: ["VueJs", "Vuex", "SCSS"],
    blurb: "Ski & snowboard school",
    link: "https://rideandsmile-snowsports.netlify.app/",
  },
  {
    id: 4,
    title: "Cast",
    image: CastoImg,
    description:
      "Personnal project. Social media for sharing some stories with my friends. Features: login, search, controlled form to create new stories",
    tags: ["Vue", "Vuex", "SCSS", "NodeJS", "PostgreSQL"],
    blurb: "Little Social App",
    link: "https://github.com/Mamouel/cast-vue-frontend",
  },
  {
    id: 5,
    title: "Cards deck",
    image: Cards,
    description:
      "Random card picker (52 cards deck). You can pick a random card, shuffle deck and show/hide cards",
    tags: ["React", "SCSS"],
    blurb: "Random card picker",
    link: "https://random-card-picker.herokuapp.com/",
  },
  {
    id: 6,
    title: "Search on Spotify API",
    image: SpotifyImg,
    description:
      "App using Spotify API to connect to your Spotify account and perform searches on Artists",
    tags: ["React", "SCSS", "NodeJS"],
    blurb: "App using Spotify API",
    link: "https://spotify-api-example.herokuapp.com/",
  },
  {
    id: 7,
    title: "UGI",
    image: Ugi,
    description: "Frontend integration",
    tags: ["XML", "XSLT", "LESS"],
    blurb: "Frontend integration",
    link: "http://www.ugi.ca/",
  },
  {
    id: 8,
    title: "Pistachio",
    image: PistachioImg,
    description:
      "Final project from DecodeMTL's bootcamp. Web App for create cookbooks online and share recipe with other users. Features: login, controlled form to create recipe, print, share, search,multiple cookbooks",
    tags: ["React", "Firebase", "SCSS"],
    blurb: "DecodeMTL's bootcamp",
    link: "https://www.youtube.com/watch?v=a3zvyXSLGmE&feature=youtu.be",
  },
];

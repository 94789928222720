import React from "react";

import avatar from "../../../../style/images/IMG_4776.JPG";
import linkedinLogo from "../../../../style/images/linkedin-footer.png";
import githubLogo from "../../../../style/images/github-footer.png";
import mailLogo from "../../../../style/images/mail-footer.png";


import "./contact.css";

const Contact = () => {
  return(
    <section className="contact-main-ctn">
      <h1 className="about-title">About</h1>
      <h1 className="about-title2">— me</h1>
      <div className="contact-content-ctn">
        <div className="contact-image-ctn">
          <img className="contact-image" src={avatar} alt="avatar" />
        </div>
        <div className="contact-ctn">
          <div className="about-ctn" >
            <div className="about-subtitle">
              "A biologist who loves coding"
            </div>
            <div className="about-description">
              I started programmation through biostatistics with R software.
              Progressively, I started tinkering with Web technologies like HTML and CSS.
              Currently, I use mostly React (Javascript library) to develop scalable and responsive Single-page-applications.
            </div>
            <div className="contact-logo-ctn">
              <a href="https://www.linkedin.com/in/emmanuel-dodier/" target="_blank" rel="noopener noreferrer"><img className="contact-logo" src={linkedinLogo} alt="linkedIn" /></a>
              <a href="https://github.com/Mamouel" target="_blank" rel="noopener noreferrer"><img className="contact-logo" src={githubLogo} alt="Github" /></a>
              <a href="mailto:e-dodier@hotmail.fr" target="_blank" rel="noopener noreferrer"><img className="contact-logo" src={mailLogo} alt="Mail" /></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;




import React from "react";

import App from "./App";


import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const Root = (props) => (
    <Router>
        <Switch>
          <Route exact path="/" component={App} />
          <Route
            component={() => (
              <div style={{ textAlign: "center" }}>
                <div style={{ margin: 40 }}>CAREFUL MATE - NOT FOUND</div>
                <img alt="alphie not happy" src="" />
              </div>
            )}
          />
        </Switch>
    </Router>
);

export default Root;
import React, { Component } from 'react';
import scrollToComponent from 'react-scroll-to-component';

import ProjectCard from "./components/ProjectsCards";
import ProjectPage from "./components/ProjectPage"
import { projectsArray } from "./projectsArray"

import "./projects.css";

class Projects extends Component {

  constructor() {
    super();
    this.state = {
      isProject: false,
      projectID: undefined,
      title: "",
      description: "",
      image: null,
      tags: [],
      blurb: "",
      link: ""
    };
  };

  toggleProjectPage = (project) => {
    scrollToComponent(this.projects, {offset: 0, align: 'top', duration: 750});
    this.setState({
      isProject: true,
      projectID: project.id,
      title: project.title,
      description: project.description,
      image: project.image,
      tags: project.tags,
      blurb: project.blurb,
      link: project.link,
    });
  };

  toggleProjectMosaic = () => {
    scrollToComponent(this.project, {offset: 0, align: 'top', duration: 750});
    this.setState({
      isProject: false
    });
  };

  render() {
    const isProject = this.state.isProject;

    return (
      !isProject ?
      <div className="projects-main animated fadeInLeft" ref={(section) => { this.projects = section; }}>
        <div className="projects-title" style={{ paddingRight: 120 }}>Pro</div>
        <div className="projects-title">— jects</div>
        <div className="projects-ctn">
          {projectsArray.map((project, index) => {
            return (
              <div className="project-card-ctn" onClick={() => this.toggleProjectPage(project)} key={index}>
                <ProjectCard 
                  image={project.image}
                  title={project.title}
                  alt={project.title}
                  description={project.description}
                  blurb={project.blurb} 
                />
              </div>
            )
          })}
        </div>     
      </div>
      : 
      <div className="project-page-main animated fadeInRight" ref={(section) => { this.project = section; }}>
        <ProjectPage 
          toggleProjectMosaic={this.toggleProjectMosaic}
          title={this.state.title}
          description={this.state.description}
          image={this.state.image}
          tags={this.state.tags}
          blurb={this.state.blurb}
          link={this.state.link}
        />
      </div>
    );
  };
};


export default Projects;
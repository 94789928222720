/* @flow */
import * as React from 'react';

import "./skills-panel.css";


type Props = {
  title: string,
  title2: string,
  skills: Array,
}

const SkillsPanel = (props: Props) => {
  return (
    <div className="skills-panel-ctn">
      <div  className="skills-panel-title-ctn">
        <div className="skills-panel-title">
          {props.title}
        </div>
        <div className="skills-panel-title">
          {props.title2}
        </div>
      </div>
      <div className="skills-panel-list-ctn"  >
        <ul className="skills-panel-list">
          {props.skills.map((skill, index) => {
            return(
              index % 2 === 0 ?
              <li key={index} className="skills-panel-content">{skill}</li> :
              <li key={index} className="skills-panel-content skills-panel-content-light">{skill}</li> 
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SkillsPanel;
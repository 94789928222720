import React, { Component } from 'react';

import Particles from "react-particles-js";
import AOS from "aos";
import Typing from "react-typing-animation";
import scrollToComponent from 'react-scroll-to-component';
import Button from "@material-ui/core/Button";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import particleParams from "./particleParamsHome";
import Skills from "./components/Skills/Skills";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";


import logo from "../../style/images/LOGOED.png";
import logoGithub from "../../style/images/github.png";
import logoMail from "../../style/images/mail.png";
import logoLinkedin from "../../style/images/linkedin.png";
import scrollBtn from "../../style/images/scrollBtn.png";

import "./homePage.css";
import 'aos/dist/aos.css';

AOS.init();
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

class HomePage extends Component {
  constructor() {
    super()
    this.state = {
      toggleMenu: false,
      top: false
    };
};

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", () => {
      document.getElementById("logo").style.backgroundColor="#081d3c";
    });
  };

  toggleMenu = () => {
    if(!this.state.toggleMenu) {
      document.getElementById("menu").style.display="block";
      document.getElementById("menu").className="navbar-btn-ctn hideOnMobile animated fadeInLeft";
      this.setState({
        toggleMenu: true
      });
    } else {
      document.getElementById("menu").className="navbar-btn-ctn hideOnMobile animated fadeOutLeft";
      this.setState({
        toggleMenu: false
      });
    };
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render () {
    return (
      <div className="main-container" > 
        <div className="page-ctn">
          <div className="header-ctn">
            <nav id="logo" className="navbar-ctn animated fadeInLeft" >
              <Button className="logo-btn hideOnMobile" onClick={this.toggleMenu}>
                <img className="logo-img" src={logo} alt="logo" />
              </Button>
              <div id="menu" className="navbar-btn-ctn hideOnMobile" style={{ display: "none" }}>
                <Button onClick={() => scrollToComponent(this.home, {offset: 0, align: 'top', duration: 1500})}>Home</Button>
                <Button onClick={() => scrollToComponent(this.skills, {offset: 0, align: 'top', duration: 1500})}>Skills</Button>
                <Button onClick={() => scrollToComponent(this.projects, {offset: 0, align: 'top', duration: 1500})}>Projects</Button>
                <Button onClick={() => scrollToComponent(this.contact, {offset: 0, align: 'top', duration: 1500})}>Contact</Button>
              </div>
              <div className="mobile-menu" >
              <Button className="logo-btn" onClick={this.toggleDrawer("top", true)}>
                <img className="logo-img" src={logo} alt="logo" />
              </Button>
              <SwipeableDrawer
                anchor="top"
                open={this.state.top}
                onClose={this.toggleDrawer('top', false)}
                onOpen={this.toggleDrawer('top', true)}
              >
                <div
                  tabIndex={0}
                  role="button"
                  onClick={this.toggleDrawer('top', false)}
                  onKeyDown={this.toggleDrawer('top', false)}
                >
                  <Button onClick={() => scrollToComponent(this.home, {offset: -50, align: 'top', duration: 1500})}>Home</Button>
                  <Button onClick={() => scrollToComponent(this.skills, {offset: -50, align: 'top', duration: 1500})}>Skills</Button>
                  <Button onClick={() => scrollToComponent(this.projects, {offset: -50, align: 'top', duration: 1500})}>Projects</Button>
                  <Button onClick={() => scrollToComponent(this.contact, {offset: -50, align: 'top', duration: 1500})}>Contact</Button>
                </div>
              </SwipeableDrawer>
              </div>
            </nav>
            <section className="header-main" data-aos="fade-in" ref={(section) => { this.home = section; }}>
              <Particles 
                className="particles hideOnMobile"
                params={particleParams}
              />
              <div>
                <div className="header-name">Hi, I'm Manu</div>
              </div>
              <Typing speed={100}>
                <Typing.Delay ms={500} />
                <div className="header-title">
                  <div>Frontend Web Developer</div>
                </div>
              </Typing>
              <div className="contact-header-ctn" >
                <a href="https://www.linkedin.com/in/emmanuel-dodier/" target="_blank" rel="noopener noreferrer"><img onMouseOver={this.changeImgOnHover} src={logoLinkedin} alt="linkedin" /></a>
                <a href="https://github.com/Mamouel" target="_blank" rel="noopener noreferrer"><img src={logoGithub} alt="github" /></a> 
                <a href="mailto:e-dodier@hotmail.fr" target="_blank" rel="noopener noreferrer"><img src={logoMail} alt="mail" /></a> 
              </div>
              <div className="btn-scroll-down">
                <Button onClick={() => scrollToComponent(this.skills, {offset: -50, align: 'top', duration: 1500})}>
                  <img src={scrollBtn} alt="scroll-down" />
                </Button>
              </div>              
            </section>
            <section ref={(section) => { this.skills = section; }}>
              <Skills />
            </section>
            <section ref={(section) => { this.projects = section; }}>
              <Projects />
            </section>
            <section ref={(section) => { this.contact = section; }}>
              <Contact />
            </section>
          </div>
        </div>
      </div>
    );
  };
};


export default HomePage;
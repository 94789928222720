import React from "react";

import SkillsPanel from "./components/SkillsPanel";

import "./skills.css";

const Skills = () => {

	const backendSkills = ["NodeJS", "Express", "Firebase", "PostgreSQL", "MySQL"];
	const frontendSkills = ["HTML5 / JSX", "CSS3 / SASS", "Javascript", "React / Redux", "VueJS / Vuex"];
	const toolsSkills = ["Git / Github", "Circleci", "Webpack", "Postman",  "Docker"];
	const learningSkills = ["Flow", "Typescript", "CI / CD", "Unit Testing", "E2E testing"];

	return(
		<div>
			<section className="skills-section">
				<div className="skills-ctn">
					<div className="dev-skills-ctn">
						<SkillsPanel title="Front" title2="— end" skills={frontendSkills}/>
					</div>
					<div className="dev-skills-ctn">
						<SkillsPanel title="Back" title2="— end" skills={backendSkills}/>
					</div>
					<div className="dev-skills-ctn">
						<SkillsPanel title="Tools" title2="— used" skills={toolsSkills}/>
					</div>
					<div className="dev-skills-ctn">
						<SkillsPanel title="Lear" title2="— ning" skills={learningSkills}/>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Skills;





/* @flow */
import * as React from 'react';

import "./projects-cards.css";

type Props = {
  image: String,
  title: String,
  alt: String,
  description: String,
  blurb: String,
};

const CardProjectContainer = (props: Props) => {

  return (
    <div className="card-ctn" >
      <img src={props.image} alt={props.alt} className="project-image"/>
      <div className="overlay overlay1">
        <div className="text">{props.title}</div>
        <div className="blurb">{props.blurb}</div>
      </div>
    </div>
  );
};


export default CardProjectContainer;